import React from "react"
import Feature from "./Feature";
import IEmergencias from "../../../images/svgs/EmergenciasDEF.svg"
import IConstantes from "../../../images/svgs/ConstantesDEF.svg"
import ICaidas from "../../../images/svgs/CaidaDEF.svg"
import IAviso from "../../../images/svgs/AvisoDEF.svg"
import IInternacional from "../../../images/svgs/Cobertura_Nacional.svg"
import ILlamada from "../../../images/svgs/LlamadasDEF.svg"
import IParaEllos from "../../../images/svgs/RelojParaEllosDEF.svg"
import IApp from "../../../images/svgs/family.svg"

const AllFeatures = ({intl, layoutTypeCompra}) => {
    return [
        <Feature key="f1" {...{layoutTypeCompra: layoutTypeCompra, title: intl.formatMessage({id: "Feature1Title"}), description:intl.formatMessage({id: "Feature1Description"}), icon: <IEmergencias/>}}/>,
        <Feature key="f2" {...{layoutTypeCompra: layoutTypeCompra, title: intl.formatMessage({id: "Feature2Title"}), description:intl.formatMessage({id: "Feature2Description"}), icon: <IConstantes/>}}/>,
        <Feature key="f3" {...{layoutTypeCompra: layoutTypeCompra, title: intl.formatMessage({id: "Feature3Title"}), description:intl.formatMessage({id: "Feature3Description"}), icon: <ICaidas/>}}/>,
        <Feature key="f4" {...{layoutTypeCompra: layoutTypeCompra, title: intl.formatMessage({id: "Feature4Title"}), description:intl.formatMessage({id: "Feature4Description"}), icon: <IAviso/>}}/>,
        <Feature key="f5" {...{layoutTypeCompra: layoutTypeCompra, title: intl.formatMessage({id: "Feature5Title"}), description:intl.formatMessage({id: "Feature5Description"}), icon: <IInternacional/>}}/>,
        <Feature key="f6" {...{layoutTypeCompra: layoutTypeCompra, title: intl.formatMessage({id: "Feature6Title"}), description:intl.formatMessage({id: "Feature6Description"}), icon: <ILlamada/>}}/>,
        <Feature key="f7" {...{layoutTypeCompra: layoutTypeCompra, title: intl.formatMessage({id: "Feature7Title"}), description:intl.formatMessage({id: "Feature7Description"}), icon: <IParaEllos/>}}/>,
        <Feature key="f8" {...{layoutTypeCompra: layoutTypeCompra, title: intl.formatMessage({id: "Feature8Title"}), description:intl.formatMessage({id: "Feature8Description"}), icon: <IApp/>}}/>
        ]
}

export default AllFeatures
