import React, { useEffect } from "react"
import anime from "animejs"
import styled, { ThemeProvider } from "styled-components"
import theme from "../../../styles/theme"
import GlobalStyles from "../../../styles/GlobalStyles"
import ToastNotification from "../toastNotification"
import Footer from "./footer"
import PropTypes from "prop-types"
import { injectIntl } from "gatsby-plugin-intl"
import NavWatches from "../NavWatches/NavWatches"
import Loadable from "@loadable/component";
import "../../../static/fonts/fonts.css"

const CallCenterBanner = Loadable(() => import("../../commonV3/CallCenterBanner"))

const LayoutWatches = ({location, children, showToast, layoutType}) => {
  useEffect(() => {
    anime.timeline({ loop: false }).add({
      targets: document.body,
      opacity: 1,
      duration: 500,
      easing: "easeInExpo",
    })
  }, [])

  return (
    <ThemeProvider theme={theme}>
        <LayoutWrapper>
        <GlobalStyles />
        {showToast && <ToastNotification />}
        <CallCenterBanner fallback={(<div/>)} {...{ location }} />
        <NavWatches {...{ layoutType, location }} />
        {children}
        <Footer {...{ location }} />
      </LayoutWrapper>
    </ThemeProvider>
  )
}


LayoutWatches.propTypes = {
  children: PropTypes.node.isRequired,
}

const LayoutWrapper = styled.div`
  position: relative;
  align-items: center;
  //margin: auto;
  overflow: hidden;

  strong { font-family: ${({theme}) => theme.font.medium500}; }
    
    
  .container {
    position: fixed;
    background: white;
    bottom: 0;
    z-index: 1000000;
    padding: 10px;
    font-size: small;
  }
  .btn.btn-primary.btn-lg.mx-2{
    background-color: #7066FF;
    color: white;
  }
  button {
    padding: 12px;
  }
`

export default injectIntl(LayoutWatches)
