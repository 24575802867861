import React, {useState} from "react"
import {injectIntl} from "gatsby-plugin-intl"
import styled from "styled-components"
//import Down from "../../../images/svgs/featuresicondown.svg";
//import Up from "../../../images/svgs/featuresiconup.svg";

const Feature = ({title, description, icon, layoutTypeCompra}) => {
    const [isExpanded, setExpanded] = useState(false);
    const handleClick = function () {
        setExpanded(!isExpanded);
    }

    return (
        <FeatureEmergenciesSectionWrapper  {...{ layoutTypeCompra}}>
            <div className="Container">
                {icon}
                <div className="Title">{title}</div>
            </div>
            <div className="Description">{description}</div>
        </FeatureEmergenciesSectionWrapper>
    )
}


const FeatureEmergenciesSectionWrapper = styled.div`
    text-align: center;
    display: flex;
    justify-content: left;
    align-items: unset;
    flex-direction: column;
    padding: 18px;
    flex: 1 1 20%;
    @media (max-width: ${props => props.theme.screen.xl}) {
        flex: 1 1 40%;
    }
    background: ${({theme}) => theme.color.neutrals.neutral0};
    
    border-radius: 20px;
    order: 0;
    align-self: stretch;

    .Container {
        display: flex;
        align-items: center;
        text-align: center;
        padding: 16px 12px;
        gap: 14px;
        flex-direction: column;
        
        @media (max-width: ${props => props.theme.screen.xl}) {
            align-items: center;
        }

    }

    .Title {
        margin-top: 8px;
        ${({theme}) => theme.font_size.regular};
        font-family: ${({theme}) => theme.font.bold};
    }

    .Description {
        text-align: center;

        }

    }

    svg {
        min-width: 48px;
        min-height: 48px;
    }
}
`


export default injectIntl(Feature)
