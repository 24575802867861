import React from "react"
import {injectIntl, Link} from "gatsby-plugin-intl"
import {ActionsContainer, Brand, Nav, StyledContainer} from "./style"
import Logo from "../../../images/svgs/logo.svg"
import MoreInfoButton from "../../commonV3/MoreInfoButton";
import styled from "styled-components";
//import BlogButton from "../../commonV3/BlogButton";

const NavWatches = ({ intl, layoutType, location }) => {
  const goToTop = () => {
    window.scrollTo({ top: 66, behavior: "smooth" })
  }

  return (
      <>
        {
          (!new URLSearchParams(location.search)?.get("userId")) && (<Nav position={layoutType === "buy" ? "relative" : "fixed"}>
              <StyledContainer>
                <Brand>
                  <Link to={`/`} onClick={goToTop}>
                    <Logo alt="Durcal" id="LogoDurcal" />
                  </Link>
                </Brand>

                {layoutType !== "buy" && (
                    <ActionsContainer>

                    </ActionsContainer>
                )}
              </StyledContainer>
            </Nav>)
        }

      </>
  )
}

const ButtonText = styled.div`
  margin: 0;
  text-align: center;
  ${({theme}) => theme.font_size.regular};
  font-family: ${({theme}) => theme.font.bold};
`

const optionsNavbar = styled.div`
  display: flex;
  
`

export default injectIntl(NavWatches)
