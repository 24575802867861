import React from "react"
import {injectIntl} from "gatsby-plugin-intl"
import Button from "../common/button";
import {navigate} from "@reach/router";
import {checkLanguage, paths} from "../../utils";

const MoreInfoButton = ({children,location,intl,type,eventType}) => {
    return (
        <Button id="gta_click_pay" className="moreinfo"
                onClick={()=>{window.dataLayer.push({'event': 'buyClick','eventType': eventType});navigate(checkLanguage(location, intl)+
                    paths[intl.locale].compra+"/"+location.search)}}
                rounded
                btnType={type}
                fullwidth={true}
        >
            {children}
        </Button>
    )
}

export default injectIntl(MoreInfoButton)
