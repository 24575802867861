import React from "react"
import {injectIntl} from "gatsby-plugin-intl"
//import {FormattedMessage} from "gatsby-plugin-intl";
import styled from "styled-components"
import FAQ from "./FAQ";

const FAQSSection = ({intl}) => {


    return (
        <FAQSCardSectionWrapper>
            <div className="FAQsWrapper" id="FAQS">
                <h5>{intl.formatMessage({ id: "footer_link_faqs" })}</h5>
                <FAQSWrapper>
                    <FAQ {...{title: intl.formatMessage({id: "faq6Title"}), description:intl.formatHTMLMessage({id: "faq6Description"}), eventType:"price"}}/>
                    <FAQ {...{title: intl.formatMessage({id: "faq7Title"}), description:intl.formatMessage({id: "faq7Description"})}}/>
                    <FAQ {...{title: intl.formatMessage({id: "faq9Title"}), description:intl.formatMessage({id: "faq9Description"})}}/>
                    <FAQ {...{title: intl.formatMessage({id: "faq10Title"}), description:intl.formatMessage({id: "faq10Description"})}}/>
                    <FAQ {...{title: intl.formatMessage({id: "faq11Title"}), description:intl.formatMessage({id: "faq11Description"})}}/>
                    <FAQ {...{title: intl.formatMessage({id: "faq12Title"}), description:intl.formatMessage({id: "faq12Description"}), eventType:"fall"}}/>
                    <FAQ {...{title: intl.formatMessage({id: "faq13Title"}), description:intl.formatMessage({id: "faq13Description"}), eventType:"sosbutton"}}/>
                    <FAQ {...{title: intl.formatMessage({id: "faq1Title"}), description:intl.formatMessage({id: "faq1Description"})}}/>
                    <FAQ {...{title: intl.formatMessage({id: "faq2Title"}), description:intl.formatMessage({id: "faq2Description"}), eventType:"mpa"}}/>
                    <FAQ {...{title: intl.formatMessage({id: "faq3Title"}), description:intl.formatMessage({id: "faq3Description"})}}/>
                    <FAQ {...{title: intl.formatMessage({id: "faq15Title"}), description:intl.formatMessage({id: "faq15Description"})}}/>
                    <FAQ {...{title: intl.formatMessage({id: "faq5Title"}), description:intl.formatMessage({id: "faq5Description"})}}/>
                    <FAQ {...{title: intl.formatMessage({id: "faq14Title"}), description:"",moreInfo:true}}/>
                </FAQSWrapper>
            </div>

        </FAQSCardSectionWrapper>
    )
}


const FAQSCardSectionWrapper = styled.div`
  margin: 16px 0 16px 0;
  
  h5 {
    margin: 0;
    ${({ theme }) => theme.font_size.large};
    font-family: ${({ theme }) => theme.font.bold};
    padding: 16px 16px 24px;
    @media (min-width: ${props => props.theme.screen.xl}) {
      ${({ theme }) => theme.font_size.xxlarge};
    }
  }
  
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  /* Primary (Purple)/10 */
  background: #FFFFFF;

  /* bakcround blur */
  

  /* Note: backdrop-filter has minimal browser support */
  border-radius: 20px;
  @media (min-width: ${props => props.theme.screen.xl}) {
    border-radius: unset;
    margin: 0;
    align-items: center;
    padding-top: 72px;
    padding-bottom: 100px;
  }

  /* Inside auto layout */
  flex: none;
  order: 2;
  flex-grow: 0;
  
  .FAQsWrapper {
    @media (min-width: ${props => props.theme.screen.xl}) {
      width: 972px;
    }
  }
`
const FAQSWrapper = styled.div`
    
`




export default injectIntl(FAQSSection)
