import React, {useState} from "react"
import {FormattedMessage, injectIntl} from "gatsby-plugin-intl"
import styled from "styled-components"
import Plus from "../../../images/svgs/plus.svg";
import Minus from "../../../images/svgs/minus.svg";

const Feature = ({intl, title, description, moreInfo, eventType}) => {
    const [isExpanded, setExpanded] = useState(false)

    const handleClick = function () {
        window.dataLayer.push({'event': 'faqClick','eventType': eventType});
        setExpanded(!isExpanded);
    }

    const moreInfoClick = function () {
        window.dataLayer.push({'event': 'moreinfoClick'});
    }

    return (
        <FAQSectionWrapper>
                <FaqTitle onClick={!moreInfo?handleClick:moreInfoClick}>
                    <div className={"Title"}>{title}</div>
                    {(isExpanded ? <Minus/> : <Plus/>)}
                </FaqTitle>
                <div className={"Description"+(isExpanded? "Expand": "")}>
                    <FormattedMessage
                        id="faq"
                        defaultMessage={description}
                        values={{
                            strong: chunks => <strong>{chunks}</strong>,
                            br: <br />
                        }}
                    />
                </div>
        </FAQSectionWrapper>
    )
}


const FAQSectionWrapper = styled.div`
  /* Auto layout */
  display: flex;
  flex-direction: column;
  
  /* todo: mediaquery for desktop 20% width*/
  width: 100%;
  
  /* White */
  background-color: transparent;
  
  
  /* Inside auto layout */
  flex: none;
  order: 0;
  border-radius: 0;
  
  border-top: 1px solid #F9F9F9;

  strong { font-family: ${({theme}) => theme.font.bold}; }

  h3 {
    ${({theme}) => theme.font_size.small};
    font-family: ${({theme}) => theme.font.extraBold};
  }

  .Description {
    display: none;
  }
  
  .DescriptionExpand {
    padding: 16px;
    background: white;
    border-radius: 0;
    ${({ theme }) => theme.font_size.small};
    font-family: ${({ theme }) => theme.font.thin};
  }
`

const FaqTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  padding: 16px;
  justify-content: space-between;
  
  cursor: pointer;

  .Title {
    ${({ theme }) => theme.font_size.small};
    font-family: ${({ theme }) => theme.font.bold};
    @media (min-width: ${props => props.theme.screen.xl}) {
      ${({ theme }) => theme.font_size.regular};
    }
  }
  
  svg {
    min-width: 16px;
  }
  
  
`




export default injectIntl(Feature)
