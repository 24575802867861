import styled from "styled-components"
import Img from "gatsby-image"
import {Container} from "../../global"

export const Nav = styled.nav`
      padding: 10px;
      display: block;

      position: relative;
      width: 100%;
      z-index: 1000;
      background: #F9F9F9;
            //background: ${props => (props.scrolled ? `white` : null)};
      //background-image: linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.25));
      align-items: center;
      justify-content: center;
      box-shadow: 0px 4px 4px rgba(216, 216, 216, 0.2);
      transition: 0.4s cubic-bezier(0.2, 0.8, 0.2, 1);
      height: 68px;

      margin-top: 64px;

      @media (min-width: ${props => props.theme.screen.xl}) {
            margin-top: 100px;
            height: 96px;
      }

      @media (min-width: ${props => props.theme.screen.md}) {
            margin-top: 100px;
            height: 96px;
      }

      @media (min-width: ${props => props.theme.screen.md}) {
            margin-top: 80px;
            height: 96px;
      }
`

export const StyledContainer = styled(Container)`
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      padding: 0 40px;

`

export const Brand = styled.div`
      margin: auto;
      @media (min-width: ${props => props.theme.screen.xl}) {
            margin: 0;
      }
`

export const ActionsContainer = styled.div`
      display: none;
      @media (min-width: ${props => props.theme.screen.xl}) {
            display: flex;
      }
            /*@media (min-width: ${props => props.theme.screen.sm}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //width: 400px;

    p {
      ${({ theme }) => theme.font_size.regular};
      color: ${({ theme }) => theme.color.grey.grey04};
      text-align: right;
      margin-right: 10px;
    }
        
        .priceDesc {
              text-align: right;
              padding-right: 30px;
        }
  }*/
`

export const StyledLogoImage = styled(Img)`
      width: 24px;
`
